.container {
  table > tbody > tr:hover {
    & > td {
      cursor: pointer;

      &.driver {
        cursor: initial;
      }
    }
  }
}
