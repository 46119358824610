.action-button-with-icon {
  margin-left: auto;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 8px;
  }

  .reversed-caret {
    transform: rotate(180deg);
  }
}
