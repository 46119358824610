// Probably not needed, leaving for safety in case it is
.oncology-patient-overview {
  margin: auto;
  max-height: 100%;

  [class~="pane-row-value"] {
    width: 50%;
  }

  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
