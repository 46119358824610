.accordion {
  user-select: none;

  &-section {
    &-content {
      @include bordered-element($accordion-border-colour);
      background-color: $accordion-section-background-color;
      margin-bottom: $accordion-header-margin-bottom;
      padding: $accordion-section-content-padding
        $accordion-section-content-padding-x;
    }

    &-header {
      @include bordered-element($accordion-border-colour);
      align-items: center;
      background-color: $accordion-background-colour;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      font-size: $accordion-header-font-size;
      font-weight: 500;
      height: 100%;
      hyphens: auto;
      justify-content: space-between;
      line-height: $accordion-header-line-height;
      margin-bottom: $accordion-header-margin-bottom;
      padding: $accordion-section-header-padding-y $accordion-section-header-padding-x;
      word-break: break-word;
      transition: background-color 0.3s;

      &-arrow {
        @include arrow-right(6px, 6px);
        content: "\20";
        display: inline-block;
        font-size: 0;
        height: 0;
        line-height: 0;
        margin-left: 8px;
        vertical-align: middle;
        width: 0;
      }

      &-disabled {
        color: $colour-grey-600;
        cursor: not-allowed;
      }

      &-error {
        background-color: $accordion-header-error-background-color;
        transition: background-color 0.3s;
      }

      &-error::after {
        color: $accordion-header-error-icon-color;
        font-family: "FontAwesome";
        font-weight: 900;
        font-size: 20px;
        content: "\f06a";
        transition: color 0.3s;
      }

      .badge {
        min-width: auto;
      }
      .tag:first-child {
        margin-left: 0;
      }
    }

    &-subheader {
      background-color: $accordion-background-colour;
      margin-left: -$spacing-small;
      border: 1px solid $colour-grey-400;
      padding-bottom: $spacing-small;
      padding-left: $spacing-small;
      padding-top: $spacing-small;
      font-size: $accordion-header-font-size;
    }
  }

  &-section-active {
    .accordion-section-header-arrow {
      @include arrow-down(6px, 6px);
    }
  }
}
