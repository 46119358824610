// Default variables for the icon module
// $icon-{subclass?}-{element?}-{css property}

$icon-colour: $colour-grey-400;

$icon-caption-colour: $colour-grey-600;

$icon-giant-font-size: 160px;
$icon-large-font-size: $font-size-body-large;
$icon-medium-font-size: $font-size-body-medium;
$icon-regular-font-size: $font-size-body-regular;
$icon-small-font-size: $font-size-body-small;

$icon--disc-colour: $colour-white;
