.oncology-report {
  padding: 20px;

  &__invisible {
    display: none;
  }

  &__report {
    display: flex;
    flex-direction: column;
    height: 82vh;

    &__preview {

      &__document {
        width: 100%;
        height: 100%
      }
    }

    &__document {
      width: 100%;
      height: 100%;
    }
  }

  &__controls {
    border-right: 1px solid $colour-grey-400;
    min-height: 100%;
    height: 82vh;
    min-width: 600px;;
  }

  &__comment {
    height: 128px !important;
    &:disabled {
      background-color: $colour-white;
    }
  }

  &__actions {
    margin-top: 20px;

    &__button {
      &__status {
        opacity: 1 !important;
        cursor: default !important;
      }
    }
  }

  &__button {
    &__icon {
      margin-right: 5px;
    }
  }
}
