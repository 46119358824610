.tree-node {
  margin-left: 20px;

  &-expanded {
    display: block;
  }

  &-collapsed {
    display: none;
  }
}
