@import "src/scss/_tools/variables/general/colours";

.legend {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .legend-item {
    display: flex;
    align-items: center;
  }

  .legend-color-block {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border: 1px solid $colour-mine-shaft;
    border-radius: 4px;
  }

  .reclassified-as-passenger {
    background-color: #d9edf7;
  }

  .reclassified-as-driver {
    background-color: #fcf8e3;
  }
}
