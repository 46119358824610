.padded-block {
  padding: $spacing-small + 1 $spacing-inline-medium;
}

.dnd-target-area {
  border: $spacing-border-width-large solid transparent;
}

.dnd-target-area-active {
  border: $spacing-border-width-large dashed $colour-grey-300;
  opacity: 0.8;
}

.empty-table-label {
  text-align: center;
  padding: $spacing-x-small;
  background: $colour-silver;
  margin-left: auto;
  margin-right: auto;
}

.font-weight-bold {
  font-weight: bold;

  &.radio label {
    font-weight: bold;
  }
}

.d-table {
  display: table;
}

.d-flex {
  display: flex;
}

.clickable {
  cursor: pointer;
}

// Bootstrap 4+ alert style
.alert {
  &-secondary {
    background-color: #e2e3e5;
    border-color: #d6d8db;
    color: #383d41;
  }
}

.ws-nowrap {
  white-space: nowrap;
}

[role="button"] {
  cursor: pointer;
}

.icon-label {
  margin-right: $spacing-inline-small;
}

.sticky-header-z-index {
  z-index: $sticky-header-z-index;
}
